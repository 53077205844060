"use strict";

import onInitModulesInsScope from "./onInitModulesInScope";

export function init() {
    $('.js-nav__toggle').on('click', function () {
        let $close = $(this);
        let $nav = $close.closest('.js-nav');
        let $navItems = $nav.find('.js-nav__item');

        $nav.toggleClass('is-open');
        if (matchMedia('(max-width: 767px)').matches) {
            $navItems.removeClass('is-open');
        }
    });

    $('.js-nav__close').on('click', function () {
        let $close = $(this);
        let $nav = $close.closest('.js-nav');
        let $navItems = $nav.find('.js-nav__item');

        $nav.removeClass('is-open');
        $navItems.removeClass('is-open');
    });

    if (matchMedia('(min-width: 768px)').matches) {
        let $items = $('.js-nav').find('.js-nav__item');
        let $submenu = $('.js-nav__sub-menu');
        let timeout = 300;
        let timer;

        $items.on('click', function() {
            let $element = $(this);
            clearTimeout(timer);

            timer = setTimeout(function() {
                $items.removeClass('is-open');
                $submenu.removeClass('show-menu');
                $items.find('.js-nav__collapse').addClass("main-nav__link--grey");

                $element.addClass('is-open');
                $element.find('.js-nav__sub-menu').addClass('show-menu');
            }, timeout);
        });
    }

    if (matchMedia('(max-width: 767px)').matches) {
        let collapse = $('.js-nav__collapse'); 

        collapse.each(function(i){
            let $this = $(this);
            $this.removeClass('main-nav__link--grey');

            $($(this).data('target')).on('show.bs.collapse', function(){
                collapse.each(function(){
                    if(!$(this).is($this)){
                        $(this).addClass('main-nav__link--grey');
                    }else{
                        $(this).removeClass('main-nav__link--grey');
                    }
                });
            });

            $($(this).data('target')).on('hide.bs.collapse', function(){
                collapse.each(function(){
                    $(this).removeClass('main-nav__link--grey');
                });
            });
        });
    }
}
